import { FC } from "react";
import {
	PersonalInformation,
	GeneralQuestions,
	IndentifyingInformation,
	ScanReport,
	LeftPainPoint,
	RightPainPoint,
	WebcamPage,
	PHQCore,
	LeftFootProfile,
	RightFootProfile,
	Recommendations
} from "./Steps";
import { AnalysisFlowProvider, useAnalysisFlow } from "../../contexts";

import "./style.css";

// Main component
const AnalysisFlow: FC = (): JSX.Element => {
	// Wrapping AnalysisFlow in the provider to access context
	return (
		<AnalysisFlowProvider>
			<div className="h-full w-full">{<AnalysisFlowWrapper />}</div>
		</AnalysisFlowProvider>
	);
};

// Wrapping component for the steps and navigation logic
const AnalysisFlowWrapper: FC = () => {
	const { currentIdx, setFootcareAnalysis, footcareAnalysis, handleNextClick, handlePrevClick } = useAnalysisFlow();

	// Step names array
	const stepNames = [
		"Personal Information",
		"Foot Images",
		"General Questions",
		"Identify Information",
		"Generated Report",
		"Left Pain Points",
		"Left Pain Questions",
		"Right Pain Points",
		"Right Pain Questions",
		"Left Foot Profile",
		"Right Foot Profile",
		"Recomended Products"
	];

	return (
		<div className="flex flex-row w-full h-full py-5 px-5">
			{/* Sidebar */}
			<div className="w-[50px] flex flex-col h-full justify-between">
				{stepNames.map((step, idx) => (
					<div
						key={step}
						className={`bg-primary h-fit my-2 py-2 text-center font-bold flex items-center -rotate-180 step ${
							currentIdx === idx ? "step_selected shadow-inner !bg-card_background" : ""
						}`}
						style={{ writingMode: "vertical-rl" }}
					>
						<span>{currentIdx === idx && step}</span>
					</div>
				))}
			</div>

			{/* Main Content */}
			<div className="bg-card_background shadow-lg mr-5 rounded-lg w-[calc(100%-50px)] h-full p-5 overflow-auto">
				{/* Conditional rendering based on currentIdx */}
				{currentIdx === 0 && <PersonalInformation />}
				{currentIdx === 1 && <WebcamPage />}
				{currentIdx === 2 && <GeneralQuestions />}
				{currentIdx === 3 && <IndentifyingInformation />}
				{currentIdx === 4 && <ScanReport />}
				{currentIdx === 5 && <LeftPainPoint />}
				{currentIdx === 6 && <PHQCore foot="LEFT" />}
				{currentIdx === 7 && <RightPainPoint />}
				{currentIdx === 8 && <PHQCore foot="RIGHT" />}
				{currentIdx === 9 && <LeftFootProfile />}
				{currentIdx === 10 && <RightFootProfile />}
				{currentIdx === 11 && <Recommendations />}
			</div>
		</div>
	);
};

export default AnalysisFlow;
