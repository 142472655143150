import React, { SetStateAction } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export const style = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%",
	bgcolor: "background.paper",
	boxShadow: 144,
	p: 4,
	borderRadius: 5
};

interface product {
	product_image: string;
	product_name: string;
	product_category: string;
	product_area: string;
	description: string;
}

type TBasicModal = {
	open: boolean;
	handleModalOperation: (op: "OPEN" | "CLOSE") => void;
	productData: product;
};

const BasicModal: React.FC<TBasicModal> = ({ open, handleModalOperation, productData }) => {
	return (
		<div>
			<Modal
				open={open}
				onClose={() => handleModalOperation("CLOSE")}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div className="text-primary text-3xl font-bold">Details</div>
					<div className="text-secondary text-2xl">{productData.description}</div>
				</Box>
			</Modal>
		</div>
	);
};

export default BasicModal;
