// CustomToast.tsx
import React from "react";
import { toast, ToastOptions } from "react-toastify";

// Define the props for the custom toast
interface CustomToastProps {
	closeToast: () => void;
	message: string;
	// You can add more props if needed
}

interface CustomToastProps {
	closeToast: () => void;
	message: string;
	variant?: "success" | "error" | "info" | "warning";
}

// eslint-disable-next-line react/prop-types
const CustomToast: React.FC<CustomToastProps> = ({ closeToast, message, variant = "info" }) => {
	// Define styles based on variant
	const getVariantStyle = () => {
		switch (variant) {
			case "success":
				return { backgroundColor: "#d4edda", color: "#155724" };
			case "error":
				return { backgroundColor: "#f8d7da", color: "#721c24" };
			case "warning":
				return { backgroundColor: "#fff3cd", color: "#856404" };
			case "info":
			default:
				return { backgroundColor: "#d1ecf1", color: "#0c5460" };
		}
	};

	return (
		<div style={{ ...styles.container, ...getVariantStyle() }}>
			<p style={styles.message}>{message}</p>
			<button style={styles.button} onClick={closeToast}>
				OK
			</button>
		</div>
	);
};

// Inline styles for simplicity
const styles: { [key: string]: React.CSSProperties } = {
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "2vw 0"
	},
	message: {
		marginBottom: "2vw",
		fontSize: "1.5vw",
		textAlign: "center"
	},
	button: {
		padding: "8px 25px",
		backgroundColor: "#4CAF50",
		color: "white",
		border: "none",
		borderRadius: "4px",
		cursor: "pointer"
	}
};

export default CustomToast;
