import { FC, forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SettingsProps } from "../../types/settings.types";
import PhotoControl from "./photoControl";
import IntegratedComponent from "./AvailableWebcams";
import "./style.scss";
import SerialPortManager from "./SerialLightControl";

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Settings: FC<SettingsProps> = ({ openSettingsDialog, handleCloseSettingsDialog }: SettingsProps) => {
	return (
		<div>
			<Dialog
				fullScreen
				open={openSettingsDialog}
				onClose={handleCloseSettingsDialog}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: "relative", background: "rgb(96 207 99)" }}>
					<Toolbar>
						<div className="flex-1 text-4xl font-bold">Settings</div>
						<IconButton edge="start" color="inherit" onClick={handleCloseSettingsDialog} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<IntegratedComponent handleCloseSettingsDialog={handleCloseSettingsDialog} />
				<PhotoControl />
				<SerialPortManager />
			</Dialog>
		</div>
	);
};

export default Settings;
