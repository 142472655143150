import React, { useEffect, useState, useRef } from "react";
import { CheckCircle } from "@mui/icons-material";
import {
	Container,
	CssBaseline,
	Paper,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Stack
} from "@mui/material";
import { useStore } from "../../contexts";

interface AvailableWebcamsProps {
	label: string;
}
type Dot = { x: number; y: number };

type DotSettings = { dot1: Dot; dot2: Dot };

const AvailableWebcams: React.FC<AvailableWebcamsProps> = ({ label }: any) => {
	const { setCameraSettings, storeData } = useStore();
	const [webcamDevices, setWebcamDevices] = useState<any[]>([]);
	const [selectedWebcam, setSelectedWebcam] = useState<any | undefined>(
		storeData?.cameraSettings[label] || undefined
	);

	const [horizentalValue, setHorizentalValue] = useState<number[]>(
		storeData?.cameraSettings[label + "HorizentalValue"] || [0, 100]
	);
	const [verticalValue, setVerticalValue] = useState<number[]>(
		storeData?.cameraSettings[label + "VerticalValue"] || [0, 100]
	);

	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [dots, setDots] = useState<DotSettings>({
		dot1: { x: 0, y: 0 },
		dot2: { x: 0, y: 0 }
	});
	const [currentDot, setCurrentDot] = useState<"dot1" | "dot2">("dot1");
	const streamRef = useRef<MediaStream | null>(null); // Ref to store the current stream

	const init = async () => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoDevices = devices.filter((device) => device.kind === "videoinput");
			setWebcamDevices(videoDevices);

			if (selectedWebcam) {
				await startStream(selectedWebcam);
			}
		} catch (error) {
			console.error("Error accessing webcam:", error);
		}
	};

	const startStream = async (device: MediaDeviceInfo) => {
		try {
			// Stop any existing stream
			if (streamRef.current) {
				streamRef.current.getTracks().forEach((track) => track.stop());
			}

			const stream = await navigator.mediaDevices.getUserMedia({
				video: {
					deviceId: device.deviceId,
					width: storeData?.cameraSettings.width,
					height: storeData?.cameraSettings.height
				}
			});

			streamRef.current = stream; // Store the stream in ref

			if (videoRef.current) {
				videoRef.current.srcObject = stream;
			}
		} catch (error) {
			console.error("Error accessing webcam:", error);
		}
	};

	const getDots = () => {
		if (!videoRef.current) return;
		const rect = videoRef.current.getBoundingClientRect();

		const dot1 = {
			x: (storeData?.cameraSettings[label + "DOT1"][0] / 100) * rect.width,
			y: (storeData?.cameraSettings[label + "DOT1"][1] / 100) * rect.height
		};

		const dot2 = {
			x: (storeData?.cameraSettings[label + "DOT2"][0] / 100) * rect.width,
			y: (storeData?.cameraSettings[label + "DOT2"][1] / 100) * rect.height
		};

		setDots({ dot1, dot2 });
	};

	useEffect(() => {
		init();
		return () => {
			console.log("Cleaning up: Stopping webcam for", label);
			if (streamRef.current) {
				streamRef.current.getTracks().forEach((track) => track.stop());
				streamRef.current = null;
			}
			if (videoRef.current) {
				videoRef.current.srcObject = null;
			}
		};
		// Including selectedWebcam in dependencies to handle changes
	}, [selectedWebcam]);

	useEffect(() => {
		if (videoRef.current) {
			getDots();
		}
	}, [videoRef.current]);

	const handleWebcamChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
		const selected = event.target.value as MediaDeviceInfo;
		setSelectedWebcam(selected);
		setCameraSettings({ [label]: selected });

		await startStream(selected);
	};

	const handleChangeHorizental = (event: Event, newValue: number | number[], activeThumb: number) => {
		if (!Array.isArray(newValue)) {
			return;
		}
		let value: number[];
		if (activeThumb === 0) {
			value = [Math.min(newValue[0], horizentalValue[1] - 10), horizentalValue[1]];
		} else {
			value = [horizentalValue[0], Math.max(newValue[1], horizentalValue[0] + 10)];
		}
		setHorizentalValue(value);
		console.log(label + "HorizentalValue", value);
		setCameraSettings({ [label + "HorizentalValue"]: value });
	};

	const handleChangeVertical = (event: Event, newValue: number | number[], activeThumb: number) => {
		if (!Array.isArray(newValue)) {
			return;
		}
		let value: number[];
		if (activeThumb === 0) {
			value = [Math.min(newValue[0], verticalValue[1] - 10), verticalValue[1]];
		} else {
			value = [verticalValue[0], Math.max(newValue[1], verticalValue[0] + 10)];
		}
		setVerticalValue(value);
		console.log(label + "VerticalValue", value);
		setCameraSettings({ [label + "VerticalValue"]: value });
	};

	const handleClick = (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
		if (!videoRef.current) return;
		const rect = videoRef.current.getBoundingClientRect();

		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;

		const percentageX = (x / rect.width) * 100;
		const percentageY = (y / rect.height) * 100;

		setDots((prevDots) => ({
			...prevDots,
			[currentDot]: { x, y }
		}));

		setCameraSettings({ [label + currentDot.toUpperCase()]: [percentageX, percentageY] });

		// Toggle between dot1 and dot2
		setCurrentDot((prevDot) => (prevDot === "dot1" ? "dot2" : "dot1"));
	};

	return (
		<div className="w-[50%]">
			<div className="flex items-center mx-[5%]">
				<div className="text-2xl mr-[5%] min-w-[20%]">{label.toUpperCase()}:</div>
				<FormControl className="flex-1" variant="outlined">
					<InputLabel>Choose Webcam</InputLabel>
					<Select
						value={selectedWebcam || ""}
						onChange={handleWebcamChange as any}
						label={`${label} Webcam`}
						color="success"
						renderValue={(selected) => (selected ? selected.label : "Choose the desired camera")}
					>
						<MenuItem value="">
							<em>Choose the desired camera</em>
						</MenuItem>
						{webcamDevices.map((device, index) => (
							<MenuItem key={index} value={device}>
								{device.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{selectedWebcam && <CheckCircle className="text-primary" />}
			</div>
			{selectedWebcam && (
				<Stack sx={{ height: "auto", margin: "5%" }}>
					<Stack sx={{ height: "30vh" }} justifyContent={"center"} direction="row">
						<Slider
							orientation="vertical"
							value={verticalValue}
							sx={{ marginRight: 2, marginTop: 5 }}
							onChange={handleChangeVertical}
							valueLabelDisplay="auto"
						/>
						<div>
							<Slider
								value={horizentalValue}
								valueLabelDisplay="auto"
								onChange={handleChangeHorizental}
							/>
							<div className="h-[30vh] w-[40vh] m-2 overflow-hidden relative">
								<video
									ref={videoRef}
									autoPlay
									playsInline
									onClick={handleClick}
									style={{
										width: "100%",
										height: "100%",
										cursor: "crosshair"
									}}
								/>
								{dots.dot1.x !== 0 && dots.dot1.y !== 0 && (
									<div
										style={{
											position: "absolute",
											width: "10px",
											height: "10px",
											borderRadius: "50%",
											backgroundColor: "red",
											top: `${dots.dot1.y - 5}px`,
											left: `${dots.dot1.x - 5}px`,
											pointerEvents: "none"
										}}
									></div>
								)}
								{dots.dot2.x !== 0 && dots.dot2.y !== 0 && (
									<div
										style={{
											position: "absolute",
											width: "10px",
											height: "10px",
											borderRadius: "50%",
											backgroundColor: "blue",
											top: `${dots.dot2.y - 5}px`,
											left: `${dots.dot2.x - 5}px`,
											pointerEvents: "none"
										}}
									></div>
								)}
								{/* Overlay Bars */}
								<div
									id="topBar"
									style={{
										position: "absolute",
										top: 0,
										height: `${(100 - verticalValue[1]) * 0.3}vh`,
										backgroundColor: "rgba(0,0,0,0.5)",
										width: "100%",
										zIndex: 1
									}}
								></div>
								<div
									id="leftBar"
									style={{
										position: "absolute",
										top: 0,
										left: 0,
										height: "100%",
										backgroundColor: "rgba(0,0,0,0.5)",
										width: `${horizentalValue[0] * 0.4}vh`,
										zIndex: 1
									}}
								></div>
								<div
									id="rightBar"
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										height: "100%",
										backgroundColor: "rgba(0,0,0,0.5)",
										width: `${(100 - horizentalValue[1]) * 0.4}vh`,
										zIndex: 1
									}}
								></div>
								<div
									id="bottomBar"
									style={{
										position: "absolute",
										bottom: 0,
										height: `${verticalValue[0] * 0.3}vh`,
										backgroundColor: "rgba(0,0,0,0.5)",
										width: "100%",
										zIndex: 1
									}}
								></div>
							</div>
						</div>
					</Stack>
				</Stack>
			)}
		</div>
	);
};

interface IntegratedComponentProps {
	handleCloseSettingsDialog: () => void;
}

const IntegratedComponent: React.FC<IntegratedComponentProps> = ({ handleCloseSettingsDialog }) => {
	return (
		<Container maxWidth="xl">
			<CssBaseline />
			<Paper elevation={3} className="w-full p-[5%] pt-[2%] mt-5">
				<div className="text-3xl text-text bg-slate-200 px-5 py-2">Setup Cameras Here:</div>
				<div className="mt-5 px-10">
					<div className="flex flex-row w-full">
						<AvailableWebcams label="leftTop" />
						<AvailableWebcams label="rightTop" />
					</div>
					<div className="flex flex-row w-full mt-16">
						<AvailableWebcams label="leftSide" />
						<AvailableWebcams label="rightSide" />
					</div>
				</div>
			</Paper>
		</Container>
	);
};

export default IntegratedComponent;
