import { FC, useCallback, useContext } from "react";
import RightFrontFoot from "../../../assets/RightPainPointGeneral.png";
import RightSideFoot from "../../../assets/Right-Foot-Image-3.png";

import "./style.scss";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

const RightPainPoint: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, setCurrentIdx, handleNextClick, handlePrevClick } =
		useAnalysisFlow();
	const handlePainPointChange = useCallback(
		(paintPoint: string) => {
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					[paintPoint]: !prevFootcareAnalysis[paintPoint]
				});
			});
		},
		[setFootcareAnalysis]
	);
	return (
		<div className="right-pain-point h-full w-full relative flex flex-col items-center">
			<div className="sub-header text-center bg-secondary text-white">
				If You have any pain on RIGHT foot please select.
			</div>
			<div className="flex flex-row h-fit mt-16 max-h-[800px] w-full relative">
				<div className="h-full w-1/2 relative image-container">
					<img src={RightFrontFoot} alt="Responsive Example" className="responsive-image"></img>

					<div
						className={
							(footcareAnalysis.right_pain_point_1 ? "pain-point-item-selected" : "pain-point-item") +
							" right-pain-point-1"
						}
						onClick={() => handlePainPointChange("right_pain_point_1")}
					>
						1
					</div>
					<div
						className={
							(footcareAnalysis.right_pain_point_2 ? "pain-point-item-selected" : "pain-point-item") +
							" right-pain-point-2"
						}
						onClick={() => handlePainPointChange("right_pain_point_2")}
					>
						2
					</div>
					<div
						className={
							(footcareAnalysis.right_pain_point_3 ? "pain-point-item-selected" : "pain-point-item") +
							" right-pain-point-3"
						}
						onClick={() => handlePainPointChange("right_pain_point_3")}
					>
						3
					</div>
				</div>
				<div className="h-full w-2/5 relative image-container">
					<img src={RightSideFoot} alt="Responsive Example" className="responsive-image"></img>

					<div
						className={
							(!footcareAnalysis.right_pain_point_4 ? "pain-point-item" : "pain-point-item-selected") +
							" right-pain-point-4"
						}
						onClick={() => handlePainPointChange("right_pain_point_4")}
					>
						4
					</div>
					<div
						className={
							(!footcareAnalysis.right_pain_point_7 ? "pain-point-item" : "pain-point-item-selected") +
							" right-pain-point-7"
						}
						onClick={() => handlePainPointChange("right_pain_point_7")}
					>
						7
					</div>
					<div
						className={
							(!footcareAnalysis.right_pain_point_5 ? "pain-point-item" : "pain-point-item-selected") +
							" right-pain-point-5"
						}
						onClick={() => handlePainPointChange("right_pain_point_5")}
					>
						5
					</div>
					<div
						className={
							(!footcareAnalysis.right_pain_point_6 ? "pain-point-item" : "pain-point-item-selected") +
							" right-pain-point-6"
						}
						onClick={() => handlePainPointChange("right_pain_point_6")}
					>
						6
					</div>
					<div
						className={
							(!footcareAnalysis.right_pain_point_8 ? "pain-point-item" : "pain-point-item-selected") +
							" right-pain-point-8"
						}
						onClick={() => handlePainPointChange("right_pain_point_8")}
					>
						8
					</div>
				</div>
			</div>
			<div className="bottom-pointer-left" onClick={handlePrevClick}>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
			<div className="bottom-pointer-right" onClick={handleNextClick}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default RightPainPoint;
