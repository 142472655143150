import { useEffect } from "react";
import { ToastContainer, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./navigation";
import { LoaderProvider, ToastProvider } from "./contexts";
import "./App.css";

const toastOptions: ToastOptions = {
	position: "bottom-center", // Change to 'center' if you want it in the middle
	autoClose: 5000, // Duration in milliseconds
	hideProgressBar: false,
	closeOnClick: true,
	rtl: false,
	pauseOnFocusLoss: true,
	draggable: true,
	pauseOnHover: true
	// You can add more default options here
};

const App = () => {
	useEffect(() => {
		// Check if getUserMedia is available in the browser
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			// Request permission to access the webcam
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then((stream: MediaStream) => {
					// You can use the webcam stream here if needed
				})
				.catch((error: unknown) => {
					console.error("Error accessing webcam:", error);
				});
		} else {
			console.error("getUserMedia is not supported in this browser");
		}
	}, []);

	return (
		<div className="App">
			<LoaderProvider>
				<ToastProvider>
					<Navigation />
					<ToastContainer {...toastOptions} />
				</ToastProvider>
			</LoaderProvider>
		</div>
	);
};

export default App;
