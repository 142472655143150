import { FC, useCallback, useContext } from "react";
import ThemeRadioButton from "../../../components/radioButton/ThemeRadioButton";
import { FIRST_PHQs } from "../../../constants/PHQ";

import { FaHandPointRight } from "react-icons/fa";

import "./style.scss";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

const GeneralQuestions: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, setCurrentIdx, handleNextClick, handlePrevClick } =
		useAnalysisFlow();
	const handleGeneralQuestionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, flag: boolean) => {
			const { name, value, checked } = event.target;
			console.log(flag);
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					[name]: flag
				});
			});
		},
		[setFootcareAnalysis]
	);

	console.log(footcareAnalysis);

	return (
		<div className="h-full w-full relative">
			<div className="sub-header text-center bg-secondary text-white">Please Answer General Questions</div>
			<div className="pb-20 overflow-y-auto h-[90%] ml-5">
				{FIRST_PHQs.map((phq, idx) => {
					return (
						<div key={idx} className="mb-6">
							<div className="text-xl font-bold flex flex-row items-center px-2 py-3 bg-blue-gray-50 rounded-md">
								<FaHandPointRight className="question-icon" />
								<span className="text-2xl">&nbsp;&nbsp;&nbsp;{phq.label}</span>
							</div>
							<div className="flex flex-row mt-2">
								<ThemeRadioButton
									checked={footcareAnalysis[phq.name] === null ? false : footcareAnalysis[phq.name]}
									success
									handleChange={handleGeneralQuestionChange}
									label="Yes"
									name={phq.name}
								/>
								<ThemeRadioButton
									checked={footcareAnalysis[phq.name] === null ? false : !footcareAnalysis[phq.name]}
									success={false}
									handleChange={handleGeneralQuestionChange}
									label="No"
									name={phq.name}
								/>
							</div>
						</div>
					);
				})}
			</div>
			<div className="bottom-pointer-left" onClick={handlePrevClick}>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
			<div className="bottom-pointer-right" onClick={handleNextClick}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default GeneralQuestions;
