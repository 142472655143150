import { FC } from "react";
import "./style.scss";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const ScanReport: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, handleNextClick } = useAnalysisFlow();

	const parsetoInt = (len: number) => {
		const intLen = Math.floor(len);
		return intLen;
	};

	return (
		<div className="scan-report relative flex items-center justify-center h-full w-full">
			<div className="h-full max-h-[800px] max-w-[1400px] w-full relative">
				<div className="scan-image h-full w-full"></div>
				<div className="basic-details">
					<div className="name">{footcareAnalysis.name}</div>
					<div className="gender">{footcareAnalysis.gender[0]}</div>
					<div className="age">
						{footcareAnalysis.minimum_age} - {footcareAnalysis.maximum_age} yrs
					</div>
				</div>
				<div className="basic-report">Basic Foot Analysis Report</div>
				<div className="left-heel-width">{parsetoInt(footcareAnalysis.left_width * (67 / 100))} mm</div>
				<div className="left-length">
					<div className="orientation">{parsetoInt(footcareAnalysis.left_length)} mm</div>
				</div>
				<div className="left-width">
					<div className="">{footcareAnalysis.left_width} mm</div>
				</div>
				<div className="left-toe-type">
					<div className="">{footcareAnalysis.left_width_type}</div>
				</div>
				<div className="right-heel-width">{parsetoInt(footcareAnalysis.right_width * (67 / 100))} mm</div>
				<div className="right-width">
					<div className="">{footcareAnalysis.right_width} mm</div>
				</div>
				<div className="right-toe-type">
					<div className="">{footcareAnalysis.right_width_type}</div>
				</div>
				<div className="right-length">
					<div className="orientation">{parsetoInt(footcareAnalysis.right_length)} mm</div>
				</div>
				<div className="right-shoe-size">
					<div className="">{`${footcareAnalysis.right_shoe_size} (EU ${footcareAnalysis.right_shoe_size_eu})`}</div>
				</div>
				<div className="left-shoe-size">
					<div className="">{`${footcareAnalysis.left_shoe_size} (EU ${footcareAnalysis.left_shoe_size_eu})`}</div>
				</div>
				<div className="left-instep-height">
					<div>{footcareAnalysis.left_instep_height} mm</div>
				</div>
				<div className="right-instep-height">
					<div>{footcareAnalysis.right_instep_height} mm</div>
				</div>
				<div className="left-arch-height">
					<div>{footcareAnalysis.left_arch_height} mm</div>
				</div>
				<div className="right-arch-height">
					<div>{footcareAnalysis.right_arch_height} mm</div>
				</div>
				<div className="left-arch-type">
					<div>
						{["LOW", "FLAT", "Low", "High", "HIGH", "Flat"].includes(footcareAnalysis.left_arch_type)
							? `${footcareAnalysis.left_arch_type} Arch`
							: footcareAnalysis.left_arch_type}
					</div>
				</div>
				<div className="right-arch-type">
					<div>
						{["LOW", "FLAT", "Low", "High", "HIGH", "Flat"].includes(footcareAnalysis.right_arch_type)
							? `${footcareAnalysis.right_arch_type} Arch`
							: footcareAnalysis.right_arch_type}
					</div>
				</div>
			</div>

			<div className="bottom-pointer-right" onClick={handleNextClick}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default ScanReport;
