import { FC } from "react";

import "./radioButton.css";
import { event } from "jquery";

const ThemeRadioButton: FC<{
	label: string;
	success: boolean;
	checked: boolean;
	name: string;
	style?: any;
	fontSize?: string;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>, flag: boolean) => void;
}> = ({
	label,
	success,
	checked,
	name,
	style,
	handleChange,
	fontSize
}: {
	label: string;
	style?: any;
	name: string;
	success: boolean;
	fontSize?: string;
	checked: boolean | null;

	handleChange: (event: React.ChangeEvent<HTMLInputElement>, flag: boolean) => void;
}): JSX.Element => {
	return (
		<div className={"theme-radio "}>
			<div className={checked === null ? "" : success ? "success" : "failure"}>
				<label>
					<input
						type="radio"
						name={name}
						checked={checked === null ? false : checked}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, success)}
					/>
					<span className="p-2 pl-3 pr-10">{label}</span>
				</label>
			</div>
		</div>
	);
};

export default ThemeRadioButton;
