import analysis from "./analysis";
import auth from "./auth";
import settings from "./settings";

const API = {
	...analysis,
	...auth,
	...settings
};
export default API;
