import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the types for the context value
interface LoaderContextProps {
	isLoading: boolean;
	setLoading: (loading: boolean) => void;
}

// Create the context with the correct default value
const LoadingContext = createContext<LoaderContextProps | undefined>(undefined);

// Define the LoaderProvider component with correct typing
export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isLoading, setLoading] = useState<boolean>(false);

	const value: LoaderContextProps = {
		isLoading,
		setLoading
	};

	return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

// Custom hook to use the loading context
export function useLoading() {
	const context = useContext(LoadingContext);

	if (!context) {
		throw new Error("useLoading must be used within LoaderProvider");
	}

	return context;
}
