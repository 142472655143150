import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh"; // Icon for the button
import CircularProgress from "@mui/material/CircularProgress"; // Optional: Loading indicator

interface WebcamCardProps {
	selectedDeviceId: string;
	videoWidth?: number;
	videoHeight?: number;
	verticalValue?: number[];
	horizontalValue?: number[];
	dotP1?: [number, number];
	dotP2?: [number, number];
	title?: string;
}

type Dot = { x: number; y: number };

type DotSettings = { dot1: Dot; dot2: Dot };

const WebcamCard: React.FC<WebcamCardProps> = ({
	selectedDeviceId,
	videoWidth = 640,
	videoHeight = 480,
	verticalValue = [0, 100],
	horizontalValue = [0, 100],
	title = "Webcam",
	dotP1 = [0, 0],
	dotP2 = [0, 0]
}) => {
	const [dots, setDots] = useState<DotSettings>({
		dot1: { x: 0, y: 0 },
		dot2: { x: 0, y: 0 }
	});
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const streamRef = useRef<MediaStream | null>(null); // Ref to store the current stream
	const [isReloading, setIsReloading] = useState<boolean>(false); // State for loading indicator

	const getSelectedDevice = async () => {
		try {
			if (selectedDeviceId) {
				const constraints: MediaStreamConstraints = {
					video: {
						deviceId: { exact: selectedDeviceId },
						width: videoWidth,
						height: videoHeight
					}
				};
				const newStream = await navigator.mediaDevices.getUserMedia(constraints);

				// Stop any existing stream before setting a new one
				if (streamRef.current) {
					streamRef.current.getTracks().forEach((track) => track.stop());
				}

				streamRef.current = newStream;

				if (videoRef.current) {
					videoRef.current.srcObject = newStream;
				}
			}
		} catch (error) {
			console.error("Error accessing selected webcam:", error);
		}
	};

	useEffect(() => {
		getSelectedDevice();

		// Cleanup the stream on component unmount or when selectedDeviceId changes
		return () => {
			if (streamRef.current) {
				streamRef.current.getTracks().forEach((track) => track.stop());
				streamRef.current = null;
			}
			if (videoRef.current) {
				videoRef.current.srcObject = null;
			}
		};
	}, [selectedDeviceId, videoWidth, videoHeight]);

	const getDots = () => {
		if (!videoRef.current) return;
		const rect = videoRef.current.getBoundingClientRect();

		const dot1 = {
			x: (dotP1[0] / 100) * rect.width,
			y: (dotP1[1] / 100) * rect.height
		};

		const dot2 = {
			x: (dotP2[0] / 100) * rect.width,
			y: (dotP2[1] / 100) * rect.height
		};

		setDots({ dot1, dot2 });
	};

	useEffect(() => {
		// Delay to ensure the video has loaded and dimensions are available
		const handleCanPlay = () => {
			getDots();
		};

		if (videoRef.current) {
			videoRef.current.addEventListener("canplay", handleCanPlay);
		}

		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener("canplay", handleCanPlay);
			}
		};
	}, [dotP1, dotP2]);

	// Reload Stream Function
	const reloadStream = async () => {
		setIsReloading(true); // Start loading
		try {
			await getSelectedDevice(); // Re-initialize the stream
		} catch (error) {
			console.error("Error reloading the webcam stream:", error);
		} finally {
			setIsReloading(false); // Stop loading
		}
	};

	return (
		<Card className="border-4 border-primary shadow-none p-0 relative">
			<CardContent className="relative !p-0 !m-0">
				<div className="absolute top-0 left-0 p-1 font-bold text-primary bg-slate-50 !z-[999]">{title}</div>
				<div
					style={{
						position: "relative",
						height: "30vh",
						width: "40vh",
						display: "inline-block"
					}}
				>
					<video
						ref={videoRef}
						autoPlay
						playsInline
						muted
						style={{
							width: "100%",
							height: "100%",
							clipPath: `inset(${100 - verticalValue[1]}% ${100 - horizontalValue[1]}% ${
								verticalValue[0]
							}% ${horizontalValue[0]}%)`,
							objectFit: "cover"
						}}
						onLoadedMetadata={() => {
							// Ensure that the video actually starts playing once metadata is ready.
							if (videoRef.current) {
								videoRef.current.play().catch((error) => {
									console.error("Error trying to play video:", error);
								});
							}
						}}
						onCanPlay={() => {
							console.log("Video can play now");
						}}
					/>
					{dots.dot1.x !== 0 && dots.dot1.y !== 0 && (
						<div
							style={{
								position: "absolute",
								width: "10px",
								height: "10px",
								borderRadius: "50%",
								backgroundColor: "red",
								top: `${dots.dot1.y - 5}px`,
								left: `${dots.dot1.x - 5}px`,
								pointerEvents: "none"
							}}
						></div>
					)}
					{dots.dot2.x !== 0 && dots.dot2.y !== 0 && (
						<div
							style={{
								position: "absolute",
								width: "10px",
								height: "10px",
								borderRadius: "50%",
								backgroundColor: "blue",
								top: `${dots.dot2.y - 5}px`,
								left: `${dots.dot2.x - 5}px`,
								pointerEvents: "none"
							}}
						></div>
					)}
				</div>
			</CardContent>
			{/* Reload Button */}
			<Button
				variant="contained"
				color="primary"
				onClick={reloadStream}
				disabled={isReloading}
				style={{
					minWidth: "auto",
					padding: "0px",
					borderRadius: "50%",
					width: "25px",
					height: "25px",
					position: "absolute",
					right: "10px",
					bottom: "10px",
					alignItems: "center",
					display: "flex",
					justifyContent: "center"
				}}
			>
				{isReloading ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
			</Button>
		</Card>
	);
};

export default WebcamCard;
