import React, { useState, useEffect, createContext, ReactNode, useContext } from "react";

export type LocalStorageParsedValue = {
	store: any | null;
	cameraSettings: any;
};

export type StoreContextProps = {
	setStoreDetails: (data: any) => void;
	setCameraSettings: (data: any) => void;
	getLocalData: () => any;
	storeData: LocalStorageParsedValue | null;
	isLoaded: boolean;
};

const LOCAL_STORE_KEY = "Store_data";

const StoreContext = createContext<StoreContextProps>({} as StoreContextProps);

interface StoreContextProviderProps {
	children: ReactNode;
}

const initialValue = {
	store: null,
	cameraSettings: {
		width: 2592,
		height: 1944,
		leftTop: "",
		rightTop: "",
		leftSide: "",
		rightSide: "",
		leftTopVerticalValue: [0, 100],
		rightTopVerticalValue: [0, 100],
		leftTopHorizentalValue: [0, 100],
		rightTopHorizentalValue: [0, 100],
		leftSideVerticalValue: [0, 100],
		rightSideVerticalValue: [0, 100],
		leftSideHorizentalValue: [0, 100],
		rightSideHorizentalValue: [0, 100],
		leftTopDOT1: [0, 0],
		leftTopDOT2: [0, 0],
		rightTopDOT1: [0, 0],
		rightTopDOT2: [0, 0],
		leftSideDOT1: [0, 0],
		leftSideDOT2: [0, 0],
		rightSideDOT1: [0, 0],
		rightSideDOT2: [0, 0],
		leftAdjustment: "0",
		rightAdjustment: "0",
		leftPPU: "0",
		rightPPU: "0",
		leftMarker: "0",
		rightMarker: "0"
	}
};

const StoreContextProvider = ({ children }: StoreContextProviderProps) => {
	const [storeData, setStoreDataState] = useState<LocalStorageParsedValue | null>(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		getLocalData();
	}, []);

	const structuredClone = (val: any) => {
		return JSON.parse(JSON.stringify(val));
	};

	const setStoreDetails = (data: any) => {
		try {
			if (data) {
				const temp: LocalStorageParsedValue = structuredClone(storeData);
				temp.store = data;
				saveLocalData(temp);
			}
			if (data == null) saveLocalData(initialValue);
		} catch (e) {
			console.error("Error setting store data:", e);
		}
	};

	const setCameraSettings = (data: any) => {
		try {
			if (data) {
				const temp: LocalStorageParsedValue = structuredClone(storeData);
				temp.cameraSettings = { ...temp.cameraSettings, ...data };
				saveLocalData(temp);
			}
		} catch (e) {
			console.error("Error setting store data:", e);
		}
	};

	const saveLocalData = (value: LocalStorageParsedValue) => {
		try {
			const stringValue: string = JSON.stringify(value);
			localStorage.setItem(LOCAL_STORE_KEY, stringValue);
			setStoreDataState(value);
		} catch (e) {
			console.error("Error saving data to local storage:", e);
		}
	};

	const getLocalData = () => {
		try {
			const value: string | null = localStorage.getItem(LOCAL_STORE_KEY);
			let parsedValue: LocalStorageParsedValue;
			if (value) {
				parsedValue = JSON.parse(value);
				// console.log(parsedValue);
				setStoreDataState(parsedValue);
			} else {
				// Initialize with default values if none exists
				parsedValue = initialValue;
				saveLocalData(parsedValue);
			}
			setIsLoaded(true);
			return parsedValue;
		} catch (e) {
			console.error("Error retrieving data from local storage:", e);
			return null;
		}
	};

	return (
		<StoreContext.Provider
			value={{
				setStoreDetails,
				storeData,
				getLocalData,
				isLoaded,
				setCameraSettings
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};

function useStore() {
	const context = useContext(StoreContext);

	if (!context) {
		throw new Error("useStore must be used within an StoreContextProvider");
	}

	return context;
}

export { StoreContextProvider, useStore };
