// Current environment (string type with specific allowed values)
export const APP_ENV = "PROD" as "PROD" | "DEV" | "LOCAL"; // Restrict allowed values using `as`

export const version = "v1"; // Application version

// Backend URLs for different environments
export const systemForBackendURL = "https://d2w39smwctjse1.cloudfront.net";

export const prodBackendURL = "https://d2w39smwctjse1.cloudfront.net";

// Dynamically determine the URL based on APP_ENV
export const url =
	APP_ENV === "PROD"
		? prodBackendURL
		: APP_ENV === "DEV" || APP_ENV === "LOCAL"
		? systemForBackendURL
		: (() => {
				throw new Error("Invalid APP_ENV value. Must be 'PROD', 'DEV', or 'LOCAL'.");
		  })();

// Local storage key (currently empty but can be updated)
export const LOCAL_STORE_KEY = "";

// Centralized configuration object
export const Config = {
	API_URL: url, // Deep Learning API URL
	UNAUTHORIZED_EXCEPTION: false, // Flag for unauthorized exceptions
	TOKEN: null // Placeholder for authentication token
};
