import React, { FC, useCallback, useContext, useState } from "react";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { FaMale, FaFemale } from "react-icons/fa";
import { AgeRange } from "../../../types/footcare.types";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import "./style.scss";

// Shoe sizes
const shoeSizes: number[] = [];
for (let idx = 1; idx <= 12; idx++) {
	shoeSizes.push(idx);
}

// Age ranges
const ageRanges: AgeRange[] = [
	{ minimumAge: 1, maximumAge: 2 },
	{ minimumAge: 3, maximumAge: 5 },
	{ minimumAge: 6, maximumAge: 7 },
	{ minimumAge: 8, maximumAge: 10 },
	{ minimumAge: 11, maximumAge: 14 },
	{ minimumAge: 15, maximumAge: 17 },
	{ minimumAge: 18, maximumAge: 24 },
	{ minimumAge: 25, maximumAge: 34 },
	{ minimumAge: 35, maximumAge: 44 },
	{ minimumAge: 45, maximumAge: 54 },
	{ minimumAge: 55, maximumAge: 64 },
	{ minimumAge: 65, maximumAge: 999 }
];

const PersonalInformation: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, handleNextClick } = useAnalysisFlow();
	const [height_inch, setHeight_inch] = useState("");
	const [height_feet, setHeight_feet] = useState("");

	// Handle weight change
	const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newWeight = event.target.value;
		setFootcareAnalysis((prevFootcareAnalysis: any) => ({
			...prevFootcareAnalysis,
			weight: newWeight ? Number(newWeight) : 0 // Handle empty or invalid inputs
		}));
	};

	// Handle gender change
	const handleGenderChange = (gender: "MALE" | "FEMALE") => {
		console.log(footcareAnalysis);

		setFootcareAnalysis((prevFootcareAnalysis: any) => ({
			...prevFootcareAnalysis,
			gender
		}));
	};

	// Handle age range change
	const handleAgeRangeChange = (ageRange: AgeRange) => {
		// console.log(footcareAnalysis);
		// console.log(ageRange);
		const { maximumAge, minimumAge } = ageRange;
		setFootcareAnalysis((prevFootcareAnalysis: any) => ({
			...prevFootcareAnalysis,
			minimum_age: minimumAge,
			maximum_age: maximumAge
		}));
	};

	// Handle shoe size change
	const handleShoeSizeChange = (shoeSize: number) => {
		setFootcareAnalysis((prevFootcareAnalysis: any) => ({
			...prevFootcareAnalysis,
			shoe_size: shoeSize
		}));
	};

	// Handle feet input change for height
	const handleFeetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newFeet = event.target.value;
		setHeight_feet(newFeet);
		const newHeightInInches = (Number(newFeet) || 0) * 12 + (footcareAnalysis.height_inches || 0); // Calculate total height in inches
		setFootcareAnalysis((prevFootcareAnalysis: any) => ({
			...prevFootcareAnalysis,
			height: newHeightInInches // Save height in inches only
		}));
	};

	// Handle inches input change for height
	const handleInchesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newInches = event.target.value;
		setHeight_inch(newInches);
		const newHeightInInches = (Number(newInches) || 0) + (footcareAnalysis.height_feet || 0) * 12; // Calculate total height in inches
		setFootcareAnalysis((prevFootcareAnalysis: any) => ({
			...prevFootcareAnalysis,
			height: newHeightInInches // Save height in inches only
		}));
	};

	return (
		<div className="h-full relative">
			<div className="h-[90%] w-full flex items-center flex-row">
				<div className="w-1/2 flex justify-start lg:justify-between flex-col h-full mx-5">
					{/* Gender */}
					<div>
						<div className="sub-header text-center bg-secondary text-white">Choose Your Gender</div>
						<div className="flex flex-row items-center justify-center mt-5">
							<div
								className="flex flex-row items-center cursor-pointer"
								onClick={() => handleGenderChange("MALE")}
							>
								<IconButton>
									<FaMale
										className="header"
										style={{
											color: footcareAnalysis?.gender === "MALE" ? "#409F61" : "inherit"
										}}
									/>
								</IconButton>
								<div
									className="font-bold text-2xl"
									style={{
										color: footcareAnalysis?.gender === "MALE" ? "#409F61" : "inherit"
									}}
								>
									Male
								</div>
							</div>
							<div
								className="flex flex-row items-center cursor-pointer ml-5"
								onClick={() => handleGenderChange("FEMALE")}
							>
								<IconButton>
									<FaFemale
										className="header"
										style={{
											color: footcareAnalysis?.gender === "FEMALE" ? "#409F61" : "inherit"
										}}
									/>
								</IconButton>
								<div
									className="font-bold text-2xl"
									style={{
										color: footcareAnalysis?.gender === "FEMALE" ? "#409F61" : "inherit"
									}}
								>
									Female
								</div>
							</div>
						</div>
					</div>
					{/* Shoe Size */}
					<div>
						<div className="sub-header text-center my-5 bg-secondary text-white">Choose Your Shoe Size</div>
						<div className="flex justify-center">
							<div>
								{shoeSizes.map((shoeSize) => (
									<>
										<Button
											key={shoeSize}
											className={
												shoeSize === footcareAnalysis.shoe_size
													? "shoe-size-button active"
													: "shoe-size-button"
											}
											onClick={() => handleShoeSizeChange(shoeSize)}
										>
											{shoeSize}
										</Button>
										{[4, 8, 12].includes(shoeSize) ? <div /> : null}
									</>
								))}
							</div>
						</div>
					</div>

					{/* Weight */}
					<div>
						<div className="sub-header text-center my-5 bg-secondary text-white">Enter your Weight</div>
						<div className="flex flex-row items-center w-full justify-center">
							<TextField
								required={true}
								className="w-7/12"
								variant="outlined"
								size="medium"
								value={footcareAnalysis.weight}
								placeholder="Enter Your Weight"
								onChange={handleWeightChange}
								autoComplete="off"
								type="number"
							/>
							<h1 className="ml-2 font-bold">kg</h1>
						</div>
					</div>
				</div>

				<div className="w-1/2 flex justify-start lg:justify-between flex-col h-full mx-5">
					{/* Age Range */}
					<div>
						<div className="sub-header text-center bg-secondary text-white">Choose Your Age</div>
						<Grid
							container
							rowGap={3}
							columnGap={0}
							alignItems="center"
							justifyContent="center"
							className="mt-5"
						>
							{ageRanges.map((ageRange) => {
								// console.log(ageRange, ageRange.minimumAge == footcareAnalysis.minimum_age);
								return (
									<Grid
										key={ageRange.minimumAge}
										className="text-center flex px-8"
										item
										xl={6}
										lg={6}
										md={6}
										sm={ageRanges.length}
										xs={ageRanges.length}
									>
										<Button
											className={
												ageRange.minimumAge == footcareAnalysis.minimum_age &&
												ageRange.maximumAge == footcareAnalysis.maximum_age
													? "age-range-button active"
													: "age-range-button"
											}
											onClick={() => handleAgeRangeChange(ageRange)}
										>
											{ageRange.maximumAge !== 999
												? `${ageRange.minimumAge} to ${ageRange.maximumAge} yrs`
												: `${ageRange.minimumAge} yrs Above`}
										</Button>
									</Grid>
								);
							})}
						</Grid>
					</div>
					{/* Height */}
					<div>
						<div className="sub-header text-center mt-7 bg-secondary text-white">Enter your Height</div>
						<div className="flex flex-row items-center w-full justify-center mt-5">
							<TextField
								required={true}
								className="w-4/12"
								variant="outlined"
								size="medium"
								placeholder="Feet"
								onChange={handleFeetChange}
								value={height_feet || ""}
								autoComplete="off"
								type="number"
							/>
							<div className="mx-2 font-bold">ft.</div>
							<TextField
								required={true}
								className="w-4/12"
								variant="outlined"
								size="medium"
								placeholder="Inches"
								onChange={handleInchesChange}
								value={height_inch || ""}
								autoComplete="off"
								type="number"
							/>
							<div className="mx-2 font-bold">in</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bottom-pointer-right" onClick={() => handleNextClick()}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default PersonalInformation;
