import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, CssBaseline, Paper, TextField, Typography } from "@mui/material";
import ImageControl from "./ImageSlider";
import { Button } from "@mui/base";
import API from "../../services/api";
import { useLoading, useStore } from "../../contexts";

interface payloadofPpu {
	image: string;
	actual_length: string;
}

interface ImageDataObject {
	leftTop: string;
	rightTop: string;
}

const PhotoControl: React.FC = () => {
	const { setCameraSettings, storeData } = useStore();
	const { setLoading } = useLoading();
	const [leftPPU, setLeftPPU] = useState<string>(storeData?.cameraSettings.leftPPU);
	const [rightPPU, setRightPPU] = useState<string>(storeData?.cameraSettings.rightPPU);
	const [leftAdjustment, setLeftAdjustment] = useState<string>(storeData?.cameraSettings.leftAdjustment);
	const [rightAdjustment, setRightAdjustment] = useState<string>(storeData?.cameraSettings.rightAdjustment);
	const [leftMarker, setLeftMarker] = useState<string>("");
	const [rightMarker, setRightMarker] = useState<string>("");

	// Function to capture an image from a webcam and update the state
	const captureImage = async (deviceId: string, direction: keyof ImageDataObject) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const getHorizentalValue = (direction: string): number[] => {
			return storeData?.cameraSettings[direction + "HorizentalValue"];
		};

		const getVericalValue = (direction: string): number[] => {
			return storeData?.cameraSettings[direction + "VerticalValue"];
		};

		if (ctx) {
			const video = document.createElement("video");
			video.autoplay = true;
			video.playsInline = true;

			try {
				// Set the selected device as the video source
				const stream = await navigator.mediaDevices.getUserMedia({
					video: {
						deviceId,
						width: storeData?.cameraSettings.width,
						height: storeData?.cameraSettings.height
					}
				});
				video.srcObject = stream;

				// Wait for the video metadata to load
				const res = await new Promise<string>((resolve, reject) => {
					video.onloadedmetadata = () => {
						try {
							// Set canvas dimensions to match video dimensions
							canvas.width = video.videoWidth;
							canvas.height = video.videoHeight;

							// Draw video frame on canvas with adjustments
							const horizontal = getHorizentalValue(direction);
							const vertical = getVericalValue(direction);

							ctx.drawImage(
								video,
								horizontal[0],
								vertical[0],
								canvas.width - (horizontal[0] + (100 - horizontal[1])),
								canvas.height - (vertical[0] + (100 - vertical[1]))
							);

							// Convert the captured image to a Base64 string
							const base64Image = canvas.toDataURL("image/png");

							// Stop the webcam stream
							stream.getTracks().forEach((track) => track.stop());

							// Resolve the promise with the Base64 image
							resolve(base64Image);
						} catch (error) {
							// Reject the promise if an error occurs
							reject(error);
						}
					};
				});
				return res;
			} catch (error) {
				console.error(`Error accessing webcam ${deviceId}:`, error);
			}
		}
	};

	const sendImagesToApi = async (imageDataObject: any, type: any) => {
		try {
			// Remove the data URI prefix from each image
			const payloadOfPpu: payloadofPpu = {
				image: imageDataObject.replace("data:image/png;base64,", ""),
				actual_length: leftMarker
			};
			const response = await API.get_ppu(payloadOfPpu);
			console.log("Payload :", payloadOfPpu);
			console.log("Images sent successfully!", response);

			if (response) {
				if (type == "left") {
					//const data = response.data;
					setLeftPPU(response.ppu);
					setCameraSettings({ leftPPU: response.ppu });
				} else {
					setRightPPU(response.ppu);
					setCameraSettings({ rightPPU: response.ppu });
				}
			}
		} catch (error) {
			console.log("Error sending images:", error);
			// Handle errors here
		}
	};

	const handleMarker = (_direction: "LEFT" | "RIGHT", event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		console.log("valu of the input : ", typeof value, value);
		if (_direction === "LEFT") {
			setLeftMarker(value);
			setCameraSettings({ leftMarker: value });
		} else if (_direction === "RIGHT") {
			setRightMarker(value);
			setCameraSettings({ rightMarker: value });
		}
		console.log("LO : " + leftMarker + " RO :" + rightMarker);
	};

	const handleAdjusment = (_direction: "LEFT" | "RIGHT", value: string) => {
		console.log("valu of the input : ", typeof value, value);
		if (_direction === "LEFT") {
			setLeftAdjustment(value);
			setCameraSettings({ leftAdjustment: value });
		} else if (_direction === "RIGHT") {
			setRightAdjustment(value);
			setCameraSettings({ rightAdjustment: value });
		}
		console.log("LO : " + leftAdjustment + " RO :" + rightAdjustment);
	};

	const handlePpu = (_direction: "LEFT" | "RIGHT", event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		console.log("valu of the input : ", typeof value, value);
		if (_direction === "LEFT") {
			setLeftPPU(value);
			setCameraSettings({ leftPPU: value });
		} else if (_direction === "RIGHT") {
			setRightPPU(value);
			setCameraSettings({ rightPPU: value });
		}
		console.log("LO : " + leftAdjustment + " RO :" + rightAdjustment);
	};

	const captureTestImageLeft = async () => {
		setLoading(true);
		// Define the webcam IDs based on local storage values
		const leftTopDeviceId = storeData?.cameraSettings.leftTop;
		// Update the corresponding property in the capturedImagesObject
		const img = (await captureImage(leftTopDeviceId, "leftTop")) || "";
		// Send the capturedImagesObject to the API
		await sendImagesToApi(img, "left");
		setLoading(false);
	};

	const captureTestImageRight = async () => {
		setLoading(true);
		// Define the webcam IDs based on local storage values
		const rightTopDeviceId = storeData?.cameraSettings.rightTop;
		// Update the corresponding property in the capturedImagesObject
		const img = (await captureImage(rightTopDeviceId, "rightTop")) || "";
		// Send the capturedImagesObject to the API
		await sendImagesToApi(img, "right");
		setLoading(false);
	};

	return (
		<Container maxWidth="xl">
			<CssBaseline />
			<Paper elevation={3} className="w-full p-[5%] pt-[2%] mt-5">
				<div className="text-3xl text-text bg-slate-200 px-5 py-2">Camera Adjusment:</div>
				<div className="flex flex-row w-full mt-5 px-10">
					<div className="flex flex-col w-full mx-5">
						<ImageControl
							label="Left Adjustment"
							value={parseInt(leftAdjustment)}
							onChange={(newValue: any) => {
								handleAdjusment("LEFT", newValue);
							}}
						/>
						<div className="flex flex-row">
							<TextField
								placeholder="Left Marker Value"
								variant="outlined"
								size="medium"
								required={true}
								color="success"
								value={leftMarker}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMarker("LEFT", event)}
								sx={{ marginRight: "2rem" }}
							/>
							<TextField
								placeholder="Left PPU"
								color="success"
								variant="outlined"
								size="medium"
								required={true}
								value={leftPPU}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => handlePpu("LEFT", event)}
							/>
						</div>
						<Button className="capture-image-btn" onClick={captureTestImageLeft}>
							<Typography
								variant="h5"
								component="div"
								fontFamily="Quicksand, sans-serif"
								fontWeight="light"
							>
								Submit Left
							</Typography>
						</Button>
					</div>
					<div className="flex flex-col w-full mx-5">
						<ImageControl
							label="Right Adjustment"
							value={parseInt(rightAdjustment)}
							onChange={(newValue: any) => handleAdjusment("RIGHT", newValue)}
						/>
						<div className="flex flex-row">
							<TextField
								placeholder="Right Marker Value"
								color="success"
								variant="outlined"
								size="medium"
								required={true}
								value={rightMarker}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMarker("RIGHT", event)}
								sx={{ marginRight: "1rem" }}
								autoComplete="off"
							/>
							<TextField
								placeholder="Right PPU"
								color="success"
								variant="outlined"
								size="medium"
								value={rightPPU}
								required={true}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => handlePpu("RIGHT", event)}
							/>
						</div>
						<Button className="capture-image-btn" onClick={captureTestImageRight}>
							<Typography
								variant="h5"
								component="div"
								fontFamily="Quicksand, sans-serif"
								fontWeight="light"
							>
								Submit Right
							</Typography>
						</Button>
					</div>
				</div>
			</Paper>
		</Container>
	);
};

export default PhotoControl;
