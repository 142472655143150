// ToastContext.tsx
import React, { createContext, useContext } from "react";
import { toast, ToastOptions } from "react-toastify";
import CustomToast from "./CustomToast";
type Variant = "success" | "error" | "info" | "warning";

interface ToastContextProps {
	showToast: (message: string, variant: Variant, options?: ToastOptions) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = (): ToastContextProps => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error("useToast must be used within a ToastProvider");
	}
	return context;
};
export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const showToast = (message: string, variant?: Variant, options?: ToastOptions) => {
		toast((props) => <CustomToast {...props} message={message} variant={variant} />, options);
	};

	return <ToastContext.Provider value={{ showToast }}>{children}</ToastContext.Provider>;
};
