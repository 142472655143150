import { FC, useCallback, useContext, useEffect, useState } from "react";
import Button from "@mui/material-next/Button";
import { GeneralFootImg, RightFootGeneralImg, RightFootProfileImg } from "../../../assets";
import { DIAGNOSIS_DATA } from "../../../constants/diagnosis";
import "./style.scss";
import { generateDiagnosis } from "../../../utils/commonFunctions/diagnosisEngine";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

const RightFootProfile: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, setCurrentIdx, handleNextClick, handlePrevClick } =
		useAnalysisFlow();
	const [activeDiagnosis, setActiveDiagnosis] = useState({
		text: "",
		mainPara: "",
		secondText: "",
		secondPara: "",
		diagnosis: "",
		painPoint: -1
	});

	const handlePainPointClick = useCallback(
		(painPoint: number, openPopup: boolean) => {
			const _activeDiagnosis = footcareAnalysis.right_diagnosis.find(
				(eachRightDiagnosis: any) => eachRightDiagnosis.diagnosisPainPoint === painPoint
			);
			console.log(_activeDiagnosis);
			setActiveDiagnosis(
				Object.assign(
					{},
					{
						text: DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].text,
						mainPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].mainPara,
						secondText:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondText,
						secondPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondPara,
						diagnosis: _activeDiagnosis.diagnosisName,
						painPoint: _activeDiagnosis.diagnosisPainPoint
					}
				)
			);
		},
		[footcareAnalysis.right_diagnosis]
	);

	useEffect(() => {
		const rightDiagnosis = generateDiagnosis(footcareAnalysis, "RIGHT");
		console.log("rightDiagnosis-->", rightDiagnosis);

		setFootcareAnalysis((prevFootcareAnalysis: any) => {
			return Object.assign({}, prevFootcareAnalysis, {
				right_diagnosis: [...rightDiagnosis],
				right_diagnosis_pain_points: rightDiagnosis.map(
					(eachRightDiagnosis: any) => eachRightDiagnosis.diagnosisPainPoint
				)
			});
		});
		if (rightDiagnosis.length === 0) {
			setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
		} else {
			const _activeDiagnosis = rightDiagnosis[0];
			setActiveDiagnosis(
				Object.assign(
					{},
					{
						text: DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].text,
						mainPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].mainPara,
						secondText:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondText,
						secondPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondPara,
						diagnosis: _activeDiagnosis.diagnosisName,
						painPoint: _activeDiagnosis.diagnosisPainPoint
					}
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFootcareAnalysis]);

	return (
		<div className="right-foot-profile h-full w-full relative flex flex-col items-center">
			<div className="sub-header text-center bg-secondary text-white">Your Right Foot Profile</div>
			<div className="flex flex-row h-fit mt-16 max-h-[800px] w-full">
				<div className="h-full w-3/5 relative image-container">
					<img src={RightFootProfileImg} alt="" className="profile-img" />
					<div className="arch-type">{footcareAnalysis.right_arch_type}</div>
					{footcareAnalysis.right_diagnosis_pain_points.includes(3) ? (
						<div
							className={`profile-3 profile-dot ${
								activeDiagnosis.painPoint === 3 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(3, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(2) ? (
						<div
							className={`profile-2 profile-dot ${
								activeDiagnosis.painPoint === 2 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(2, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(1) ? (
						<div
							className={`profile-1 profile-dot ${
								activeDiagnosis.painPoint === 1 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(1, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(4) ? (
						<div
							className={`profile-4 profile-dot ${
								activeDiagnosis.painPoint === 4 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(4, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(7) ? (
						<div
							className={`profile-7 profile-dot ${
								activeDiagnosis.painPoint === 7 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(7, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(8) ? (
						<div
							className={`profile-8 profile-dot ${
								activeDiagnosis.painPoint === 8 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(8, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(6) ? (
						<div
							className={`profile-6 profile-dot ${
								activeDiagnosis.painPoint === 6 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(6, false)}
						/>
					) : null}
				</div>
				<div className="h-full w-2/5 pr-16">
					<div className="text-[2vw] font-bold border-l-8 border-primary pl-4">
						{activeDiagnosis.diagnosis}
					</div>
					<div className="text-[1.2vw] mt-10">{activeDiagnosis.text}</div>
				</div>
			</div>
			<div className="bottom-pointer-left" onClick={handlePrevClick}>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
			<div className="bottom-pointer-right" onClick={handleNextClick}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default RightFootProfile;
