import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Foot, LogoOther } from "../../assets";
import { useLoading, useStore } from "../../contexts";
import API from "../../services/api";
import { Button } from "@mui/material";

export default function TwoBoxPage() {
	const navigate = useNavigate();
	const { setStoreDetails } = useStore();
	const { setLoading } = useLoading();
	const valueRef = useRef<HTMLInputElement | null>(null);
	const [inputError, setInputError] = useState(false);

	const handleLoginSubmitClick = async () => {
		const machineID = valueRef.current?.value ?? null;
		if (!machineID) {
			return setInputError(true);
		}
		setInputError(false);
		setLoading(true);
		const response = await API.login(machineID);
		if (response) {
			console.log(response);
			if (response.result.length === 0) {
				return;
			}
			const included = response.result.find((item: any) => item.device_id === machineID);
			if (included) {
				console.log("value from the response", included);

				setStoreDetails(included);
				navigate("/");
			}
		}
		setLoading(false);
	};

	return (
		<div className="flex items-center h-full w-full">
			<div className="flex items-center justify-center h-full w-3/5 p-[5%]">
				<img src={Foot} alt="logo" className="" />
			</div>
			<div className="w-2/5 mx-[10%] px-5 py-[5%] min-h-[70%] flex justify-around flex-col items-center shadow-xl bg-green-50 rounded">
				<div className="flex flex-col items-center">
					<div className="text-bold text-5xl">KIOSK LOGIN</div>
					<img src={LogoOther} className="mt-[5%] mb-[12%] w-[40%]" alt="Logo" />
					<TextField
						required={true}
						className="w-[70%] !my-[10%] "
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleLoginSubmitClick();
							}
						}}
						style={{
							borderColor: inputError ? "red" : undefined
						}}
						placeholder="Enter the Machine ID"
						inputRef={valueRef}
						error={inputError}
						helperText={inputError ? "Machine ID is required" : undefined}
						autoComplete="off"
					/>
					<Button
						sx={{
							textAlign: "center",
							fontSize: "1.2vw",
							fontFamily: "inherit",
							fontWeight: "bold",
							paddingLeft: "20%",
							paddingRight: "20%",
							background: "#60cf63",
							color: "#1f2149"
						}}
						disabled={false}
						size="large"
						onClick={handleLoginSubmitClick}
					>
						Log in
					</Button>
				</div>
			</div>
		</div>
	);
}
