// src/components/SerialPortManager.jsx

import { Button, Container, CssBaseline, Paper } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const SerialPortManager = () => {
	const [port, setPort] = useState(null); // The selected serial port
	const [connected, setConnected] = useState(false); // Connection status
	const [receivedData, setReceivedData] = useState(""); // Data received from the serial port
	const [inputData, setInputData] = useState(""); // Data to send to the serial port

	const readerRef = useRef(null); // Reference to the reader
	const writerRef = useRef(null); // Reference to the writer

	// Function to request a serial port and connect
	const connect = async () => {
		try {
			// Request a port from the user
			const selectedPort = await navigator.serial.requestPort();
			await selectedPort.open({ baudRate: 9600 }); // Open the port with desired settings

			setPort(selectedPort);
			setConnected(true);

			// Set up the writer
			const encoder = new TextEncoderStream();
			const writableStreamClosed = encoder.readable.pipeTo(selectedPort.writable);
			const writer = encoder.writable.getWriter();
			writerRef.current = writer;

			// Set up the reader
			const decoder = new TextDecoderStream();
			const readableStreamClosed = selectedPort.readable.pipeTo(decoder.writable);
			const reader = decoder.readable.getReader();
			readerRef.current = reader;

			// Start reading data
			readLoop(reader);
		} catch (error) {
			console.error("There was an error opening the serial port:", error);
		}
	};

	// Function to read data from the serial port
	const readLoop = async (reader) => {
		try {
			while (true) {
				const { value, done } = await reader.read();
				if (done) {
					// Allow the serial port to be closed later.
					reader.releaseLock();
					break;
				}
				if (value) {
					setReceivedData((prevData) => prevData + value);
				}
			}
		} catch (error) {
			console.error("Error in read loop:", error);
		}
	};

	// Function to send data to the serial port
	const sendData = async () => {
		if (writerRef.current) {
			try {
				await writerRef.current.write(inputData);
				setInputData(""); // Clear the input field after sending
			} catch (error) {
				console.error("Error writing to serial port:", error);
			}
		} else {
			console.warn("Writer is not initialized.");
		}
	};

	// Function to disconnect from the serial port
	const disconnect = async () => {
		if (readerRef.current) {
			await readerRef.current.cancel();
			await readerRef.current.releaseLock();
			readerRef.current = null;
		}

		if (writerRef.current) {
			await writerRef.current.close();
			writerRef.current = null;
		}

		if (port) {
			await port.close();
			setPort(null);
			setConnected(false);
		}
	};

	// Cleanup on component unmount
	useEffect(() => {
		return () => {
			disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		// <div style={styles.container}>
		// 	<h1>React Serial Port Manager</h1>

		// 	{!connected ? (
		// 		<button onClick={connect} style={styles.button}>
		// 			Connect Serial Port
		// 		</button>
		// 	) : (
		// 		<button onClick={disconnect} style={styles.button}>
		// 			Disconnect Serial Port
		// 		</button>
		// 	)}

		// 	{connected && (
		// 		<div style={styles.serialSection}>
		// 			<h2>Send Data</h2>
		// 			<input
		// 				type="text"
		// 				value={inputData}
		// 				onChange={(e) => setInputData(e.target.value)}
		// 				onKeyPress={(e) => {
		// 					if (e.key === "Enter") {
		// 						sendData();
		// 					}
		// 				}}
		// 				placeholder="Enter data to send"
		// 				style={styles.input}
		// 			/>
		// 			<button onClick={sendData} style={styles.sendButton}>
		// 				Send
		// 			</button>

		// 			<h2>Received Data</h2>
		// 			<textarea value={receivedData} readOnly rows="10" style={styles.textarea}></textarea>
		// 		</div>
		// 	)}
		// </div>
		<Container maxWidth="xl">
			<CssBaseline />
			<Paper elevation={3} className="w-full p-[5%] pt-[2%] mt-5">
				<div className="flex flex-row justify-between items-center bg-slate-200 px-5 py-2">
					<div className="text-3xl text-text">Camera Light Control:</div>
					{!connected ? (
						<Button onClick={connect} style={styles.button}>
							Connect Serial Port
						</Button>
					) : (
						<Button onClick={disconnect} style={styles.button}>
							Disconnect Serial Port
						</Button>
					)}
				</div>
				{connected && (
					<div style={styles.serialSection}>
						<input
							type="text"
							value={inputData}
							onChange={(e) => setInputData(e.target.value)}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									sendData();
								}
							}}
							placeholder="Enter data to send"
							style={styles.input}
						/>
						<button onClick={sendData} style={styles.sendButton}>
							Send
						</button>
					</div>
				)}
			</Paper>
		</Container>
	);
};

// Inline styles for simplicity
const styles = {
	container: {
		fontFamily: "Arial, sans-serif",
		padding: "20px",
		maxWidth: "600px",
		margin: "0 auto"
	},
	button: {
		padding: "10px 20px",
		fontSize: "16px",
		cursor: "pointer"
	},
	serialSection: {
		marginTop: "20px"
	},
	input: {
		padding: "8px",
		width: "70%",
		fontSize: "16px",
		marginRight: "10px"
	},
	sendButton: {
		padding: "8px 16px",
		fontSize: "16px",
		cursor: "pointer"
	},
	textarea: {
		width: "100%",
		padding: "10px",
		fontSize: "14px",
		marginTop: "10px"
	}
};

export default SerialPortManager;
