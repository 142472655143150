export const generateDiagnosis = (footcareAnalysis: any, foot: "LEFT" | "RIGHT") => {
	const questions_obj = footcareAnalysis[foot].reduce((acc: any, obj: any) => {
		const key = Object.keys(obj)[0]; // Get the key of the current object
		acc[key] = obj[key]; // Assign the key and value to the accumulator object
		return acc;
	}, {});
	console.log(questions_obj);

	const {
		general_Q7,
		general_Q8,
		general_Q9,
		general_Q10,
		general_Q11,
		general_Q12,
		general_Q13,
		general_Q14,
		general_Q15,
		general_Q16,
		general_Q17,
		general_Q18,
		general_Q19,
		general_Q20,
		general_Q21,
		general_Q22,
		general_Q23,
		general_Q24,
		general_Q25,
		general_Q26,
		general_Q27,
		general_Q28,
		general_Q29,
		general_Q30,
		general_Q31,
		general_Q32,
		general_Q33,
		general_Q34,
		general_Q35,
		general_Q36,
		general_Q37
	} = questions_obj;
	const diagnosis: { diagnosisName: string; diagnosisPainPoint: number }[] = [];

	if ([general_Q7, general_Q8, general_Q9].includes(true)) {
		diagnosis.push({ diagnosisName: "Metatarsalgia/ Morton’s Neuroma", diagnosisPainPoint: 1 });
	}
	if ([general_Q10, general_Q11, general_Q12, general_Q13, general_Q14].includes(true)) {
		diagnosis.push({ diagnosisName: "Tibial post tendinitis", diagnosisPainPoint: 2 });
	}
	if ([general_Q15, general_Q16].includes(true)) {
		diagnosis.push({ diagnosisName: "Planter Fasciitis", diagnosisPainPoint: 3 });
	}
	if ([general_Q17, general_Q18, general_Q19].includes(true)) {
		diagnosis.push({ diagnosisName: "Achilles Tendinitis", diagnosisPainPoint: 4 });
	}
	if ([general_Q20, general_Q21, general_Q22, general_Q23, general_Q24].includes(true)) {
		diagnosis.push({ diagnosisName: "Hallux rigidus", diagnosisPainPoint: 6 });
	}
	if ([general_Q25, general_Q26, general_Q27, general_Q28, general_Q29, general_Q30, general_Q31].includes(true)) {
		diagnosis.push({ diagnosisName: "Ankle Sprain", diagnosisPainPoint: 7 });
	}
	if ([general_Q32, general_Q33, general_Q34, general_Q35, general_Q36, general_Q37].includes(true)) {
		diagnosis.push({ diagnosisName: "Midfoot Arthritis", diagnosisPainPoint: 8 });
	}
	return diagnosis;
	//alert(diagnosis);
};
