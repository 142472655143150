import React, { useContext, useEffect } from "react";
import { Routes, BrowserRouter, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { LandingPage, LoginPage, AnalysisFlowPage } from "../pages";
import { useStore } from "../contexts/StoreContextProvider";
import AppBarComp from "../components/appBar/AppBar";
import { useLoading } from "./../contexts/LoaderProvider";
import { Loader } from "../assets";

const Navigation = () => {
	const { isLoading } = useLoading();
	return (
		<BrowserRouter>
			{isLoading && (
				<div className="preloader">
					<img src={Loader} className="w-60" />
				</div>
			)}
			<div className="h-[10vh] w-full">
				<AppBarComp />
			</div>
			<div className="h-[90vh] w-full overflow-auto items-center flex justify-center">
				<Routes>
					<Route path="/login" element={<LoginPage />} />
					<Route element={<RequireAuth />}>
						<Route path="/" element={<LandingPage />} />
						{/* <Route path="/start" element={<VideoLoopPage />} /> */}
						<Route path="/analyse" element={<AnalysisFlowPage />} />
					</Route>
				</Routes>
			</div>
		</BrowserRouter>
	);
};

const RequireAuth = () => {
	const location = useLocation();
	const { storeData, isLoaded } = useStore();
	// console.log(storeData?.store);
	if (!isLoaded) return <></>;
	if (!storeData?.store)
		// Localstorage loaded but no user session found. Redirect to login page.
		return <Navigate to="/login" state={{ from: location }} />;
	else return <Outlet />; // Session present and Localstorage loaded
};

export default Navigation;
