import React, { useState, createContext, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AnalysisFlowContextType, AnalysisFlowProviderProps } from "../../types/FootcareAnalysisProps.types";
import { useToast } from "../../contexts/ToastProvider";

const AnalysisFlowContext = createContext<AnalysisFlowContextType>({} as AnalysisFlowContextType);

// Helper function to check if all pain points are false
const areAllPainPointsFalse = (painPoints: boolean[]) => painPoints.every((point) => !point);

const AnalysisFlowProvider: React.FC<AnalysisFlowProviderProps> = ({ children }) => {
	const navigate = useNavigate();
	const { showToast } = useToast();
	const [currentIdx, setCurrentIdx] = useState(0);

	const [footcareAnalysis, setFootcareAnalysis] = useState<any>({
		name: null,
		phone: null,
		gender: null,
		minimum_age: null,
		maximum_age: null,
		weight: null,
		height: null,
		height_inch: null,
		height_feet: null,
		shoe_size: null,
		general_Q1: null,
		general_Q2: null,
		general_Q3: null,
		general_Q4: null,
		general_Q5: null,
		left_length: 254,
		left_width: 104,
		left_instep_height: 77,
		left_arch_height: 21,
		right_length: 253,
		right_width: 99,
		right_instep_height: 68,
		right_arch_height: 15,
		left_shoe_size: 8,
		right_shoe_size: 9,
		left_shoe_size_eu: 8,
		right_shoe_size_eu: 8,
		left_arch_type: "High",
		right_arch_type: "Low",
		left_width_type: null,
		right_width_type: null,
		left_pain_point_1: false,
		left_pain_point_2: false,
		left_pain_point_3: false,
		left_pain_point_4: false,
		left_pain_point_5: false,
		left_pain_point_6: false,
		left_pain_point_7: false,
		left_pain_point_8: false,
		right_pain_point_1: false,
		right_pain_point_2: false,
		right_pain_point_3: false,
		right_pain_point_4: false,
		right_pain_point_5: false,
		right_pain_point_6: false,
		right_pain_point_7: false,
		right_pain_point_8: false,
		LEFT: Array.from({ length: 37 }, (_, i) => ({ [`general_Q${i + 1}`]: null })),
		RIGHT: Array.from({ length: 37 }, (_, i) => ({ [`general_Q${i + 1}`]: null })),
		left_diagnosis: [],
		left_diagnosis_pain_points: [],
		right_diagnosis: [],
		right_diagnosis_pain_points: []
	});

	// A generic handler for checking the next or previous page logic
	const handleNavigation = (isNext: boolean) => {
		const getPainPoints = (side: "left" | "right") => [
			footcareAnalysis[`${side}_pain_point_1`],
			footcareAnalysis[`${side}_pain_point_2`],
			footcareAnalysis[`${side}_pain_point_3`],
			footcareAnalysis[`${side}_pain_point_4`],
			footcareAnalysis[`${side}_pain_point_5`],
			footcareAnalysis[`${side}_pain_point_6`],
			footcareAnalysis[`${side}_pain_point_7`],
			footcareAnalysis[`${side}_pain_point_8`]
		];

		const leftPainPoints = getPainPoints("left");
		const rightPainPoints = getPainPoints("right");

		if (isNext) {
			if (
				currentIdx === 0 &&
				(!footcareAnalysis.gender ||
					!footcareAnalysis.minimum_age ||
					!footcareAnalysis.maximum_age ||
					!footcareAnalysis.weight)
			)
				return showToast("Please Fill Up All The Fields!", "error");
			else if (
				currentIdx === 2 &&
				(footcareAnalysis.general_Q1 == null ||
					footcareAnalysis.general_Q2 == null ||
					footcareAnalysis.general_Q3 == null ||
					footcareAnalysis.general_Q4 == null)
			)
				return showToast("Please Answer All The Questions!", "error");
			else if (currentIdx === 3 && (!footcareAnalysis.name || !footcareAnalysis.phone))
				return showToast("Please Write Your Name & Phone!", "error");
			else if (areAllPainPointsFalse(leftPainPoints) && currentIdx === 5) {
				setCurrentIdx(7);
			} else if (areAllPainPointsFalse(rightPainPoints) && currentIdx === 7) {
				setCurrentIdx(9);
			} else if (currentIdx === 11) {
				return navigate("/");
			} else {
				setCurrentIdx((prev) => prev + 1);
			}
		} else {
			console.log("Back");
			if (currentIdx === 11) {
				if (footcareAnalysis.left_diagnosis.length == 0 && footcareAnalysis.right_diagnosis.length == 0)
					setCurrentIdx(7);
				else if (footcareAnalysis.right_diagnosis.length == 0) setCurrentIdx(9);
				else if (footcareAnalysis.left_diagnosis.length == 0) setCurrentIdx(10);
				else {
					setCurrentIdx((prev) => prev - 1);
				}
			} else if (areAllPainPointsFalse(leftPainPoints) && currentIdx === 7) {
				setCurrentIdx(5);
			} else if (areAllPainPointsFalse(rightPainPoints) && currentIdx === 9) {
				setCurrentIdx(7);
			} else {
				setCurrentIdx((prev) => prev - 1);
			}
		}
	};

	const handleNextClick = () => handleNavigation(true);
	const handlePrevClick = () => handleNavigation(false);

	return (
		<AnalysisFlowContext.Provider
			value={{
				footcareAnalysis,
				setFootcareAnalysis,
				currentIdx,
				setCurrentIdx,
				handleNextClick,
				handlePrevClick
			}}
		>
			{children}
		</AnalysisFlowContext.Provider>
	);
};

function useAnalysisFlow() {
	const context = useContext(AnalysisFlowContext);

	if (!context) {
		throw new Error("useAnalysisFlow must be used within an AnalysisFlowProvider");
	}

	return context;
}

export { useAnalysisFlow, AnalysisFlowProvider };
