import React, { useCallback, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea, CardActions } from "@mui/material";
import BasicModal from "../../../components/TextModal";

interface product {
	product_image: string;
	product_name: string;
	product_category: string;
	product_area: string;
	description: string;
}

type MultiActionAreaCardProps = {
	productDetails: product;
};

const MultiActionAreaCard = ({ productDetails }: MultiActionAreaCardProps) => {
	const [open, setOpen] = useState(false);

	const handleModalOperation = useCallback((op: "OPEN" | "CLOSE") => {
		op === "OPEN" ? setOpen(true) : op === "CLOSE" ? setOpen(false) : null;
	}, []);

	return (
		<div className="mx-[1%] w-[25%]">
			{open && (
				<BasicModal open={open} handleModalOperation={handleModalOperation} productData={productDetails} />
			)}
			<div className="rounded-2xl shadow-md">
				<CardActionArea onClick={() => handleModalOperation("OPEN")}>
					<CardMedia
						component="img"
						className="h-[16vw]"
						image={productDetails.product_image}
						alt="product image"
						sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
					/>
					<CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<div className="text-primary text-2xl font-bold">{productDetails.product_name}</div>
						{/* <div>{productDetails.product_area}</div> */}
						<div className="text-secondary text-base font-bold">{productDetails.product_category}</div>
					</CardContent>
				</CardActionArea>
			</div>
		</div>
	);
};

export default MultiActionAreaCard;
