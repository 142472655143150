import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { D1_PHQs, D25_PHQs, D3_PHQs, D4_PHQs, D69_PHQs, D7_PHQs, D8_PHQs } from "../../../constants/PHQ";
import { useAnalysisFlow } from "../../../contexts";
import { FaHandPointRight } from "react-icons/fa";
import ThemeRadioButton from "../../../components/radioButton/ThemeRadioButton";
import { Button } from "@mui/base";
import "./style.scss";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

const PHQCore: FC<any> = ({ foot }: { foot: string }) => {
	const { footcareAnalysis, setFootcareAnalysis, setCurrentIdx, handleNextClick, handlePrevClick } =
		useAnalysisFlow();
	console.log(footcareAnalysis);

	const [corePHQs, setCorePHQs] = useState<any[]>([]);

	const handlePHQChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, success: boolean) => {
			const { name } = event.target;
			setFootcareAnalysis((prev: any) => ({
				...prev,
				[foot]: prev[foot].map((item: any) => {
					const key = Object.keys(item)[0];
					return key === name ? { [key]: success } : item;
				})
			}));
		},
		[foot, setFootcareAnalysis]
	);

	const generateCorePHQs = useCallback(() => {
		const footSpecificPHQs =
			foot === "LEFT"
				? [
						{ points: ["left_pain_point_1"], PHQs: D1_PHQs },
						{ points: ["left_pain_point_2", "left_pain_point_5"], PHQs: D25_PHQs },
						{ points: ["left_pain_point_3"], PHQs: D3_PHQs },
						{ points: ["left_pain_point_4"], PHQs: D4_PHQs },
						{ points: ["left_pain_point_6", "left_pain_point_5"], PHQs: D69_PHQs },
						{ points: ["left_pain_point_7"], PHQs: D7_PHQs },
						{ points: ["left_pain_point_8"], PHQs: D8_PHQs }
				  ]
				: [
						{ points: ["right_pain_point_1"], PHQs: D1_PHQs },
						{ points: ["right_pain_point_2", "right_pain_point_5"], PHQs: D25_PHQs },
						{ points: ["right_pain_point_3"], PHQs: D3_PHQs },
						{ points: ["right_pain_point_4"], PHQs: D4_PHQs },
						{ points: ["right_pain_point_6"], PHQs: D69_PHQs },
						{ points: ["right_pain_point_7"], PHQs: D7_PHQs },
						{ points: ["right_pain_point_8"], PHQs: D8_PHQs }
				  ];

		const combinedPHQs: any[] = footSpecificPHQs.flatMap(({ points, PHQs }) =>
			// Check if any point has a true value in footcareAnalysis
			points.some((point) => footcareAnalysis[point]) ? PHQs : []
		);

		setCorePHQs(combinedPHQs);
	}, [foot, footcareAnalysis]);

	const handleDiagnosisClick = useCallback(() => {
		const diagnosisList = [
			{ points: ["general_Q7", "general_Q8", "general_Q9"], label: "Metatarsalgia/ Morton’s Neuroma" },
			{
				points: ["general_Q10", "general_Q11", "general_Q12", "general_Q13", "general_Q14"],
				label: "Tibial post tendinitis"
			},
			{ points: ["general_Q15", "general_Q16"], label: "Planter Fasciitis" },
			{ points: ["general_Q17", "general_Q18", "general_Q19"], label: "Achilles Tendinitis" },
			{
				points: ["general_Q20", "general_Q21", "general_Q22", "general_Q23", "general_Q24"],
				label: "Hallux rigidus"
			},
			{
				points: [
					"general_Q25",
					"general_Q26",
					"general_Q27",
					"general_Q28",
					"general_Q29",
					"general_Q30",
					"general_Q31"
				],
				label: "Ankle Sprain"
			},
			{
				points: ["general_Q32", "general_Q33", "general_Q34", "general_Q35", "general_Q36", "general_Q37"],
				label: "Midfoot Arthritis"
			}
		];

		const diagnosis = diagnosisList
			.filter(({ points, label }) => points.some((point) => footcareAnalysis[foot][point] === true))
			.map(({ label }) => label)
			.join(" + ");

		alert(diagnosis);
	}, [foot, footcareAnalysis]);

	useEffect(() => {
		generateCorePHQs();
	}, [generateCorePHQs]);

	return (
		<div className="relative w-full h-full">
			<div className="sub-header text-center bg-secondary text-white">
				Please Answer The Questions For Your {foot} foot.
			</div>
			<div className="pb-20 overflow-y-auto h-[90%] ml-5">
				{corePHQs.map((corePHQ, idx) => {
					return (
						<div key={idx} className="mb-6">
							<div className="text-xl font-bold flex flex-row items-center px-2 py-3 bg-blue-gray-50 rounded-md">
								<FaHandPointRight className="question-icon" />
								<span className="text-2xl">&nbsp;&nbsp;&nbsp;{corePHQ.label}</span>
							</div>
							<div className="flex flex-row mt-2">
								<ThemeRadioButton
									checked={
										Object.values(
											footcareAnalysis[foot].find((item: any) => corePHQ.name in item)
										)[0] === true
									}
									success
									handleChange={handlePHQChange}
									label="Yes"
									name={corePHQ.name}
								/>
								<ThemeRadioButton
									checked={
										Object.values(
											footcareAnalysis[foot].find((item: any) => corePHQ.name in item)
										)[0] === false
									}
									success={false}
									handleChange={handlePHQChange}
									label="No"
									name={corePHQ.name}
								/>
							</div>
						</div>
					);
				})}
			</div>
			<div className="bottom-pointer-left" onClick={handlePrevClick}>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
			<div className="bottom-pointer-right" onClick={handleNextClick}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default PHQCore;
